import HeaderContact from "../img/header-contact.jpg";

export default function TCs() {

    return (
        <div>
            <div className="page-header" style={{backgroundImage:`url(${HeaderContact}`}}>
                <h1>Terms & Conditions</h1>
            </div>
            <main>

                <div className="container-tcs">
                    <h2>Fees & Bookings</h2>
                    <p>
                        A student may book their timeslot by Term or by Month. Invoices are issued on the first lesson
                        of the aforementioned period. The booking is recurring and will repeat until notice of
                        termination from the student, parent/guardian, or teacher. Outstanding invoices may result in
                        the suspension or termination of lessons.
                    </p>
                    <br/>
                    <p>
                        Our term weeks align with <a href="https://education.nsw.gov.au/schooling/calendars/2025"
                                                     target="_blank">public school terms</a>, commencing on the Monday.
                        In instances where the public school term starts on another day, our term also initiates on the
                        Monday of the same week. Lessons in the holidays are flexible and should be agreed upon prior to
                        the holidays between the student/guardian and JMS Student Services. Lesson are not conducted
                        on <a href="https://www.nsw.gov.au/about-nsw/public-holidays">public holidays</a>.
                    </p>

                    <h2>Instruments and Materials</h2>
                    <p>
                        It is the responsibility of the student to bring all books and instruments to the lessons each
                        week, teachers cannot guarantee that spares are available.
                    </p>

                    <h2>Missed Lessons</h2>
                    <p>
                        A minimum of 24 hours notice must be given for absences, and a catch-up credit will be given
                        (max of 3 per school term). Otherwise, the lesson will be forfeited and payment will be applied.
                        In some circumstances, a teacher may offer the lesson online before forfeiting the lesson.
                        Online lessons and catch-up lessons are subject to the availability of the teacher, room, and
                        time slot.
                    </p>

                    <h2>Rescheduling Lessons</h2>
                    <p>
                        Rescheduling lessons are subject to the availability of the teacher, room, and time slot.
                    </p>


                    <h2>Termination</h2>
                    <p>
                        Notice of termination may come from the student, parent/guardian, or teacher. Termination of the
                        agreement will come into effect at the end of the booking duration. Should there be lessons
                        between the notice and the end of the booking period, those lessons must be taken or forfeited.
                        Refunds will not be given.
                    </p>

                    <h2>Damages</h2>
                    <p>The student/parent/guardian is liable for any damages caused to school property by the
                        student/parent/guardian. This includes instruments, walls, glass, decorations, carpet,
                        soundproofing material, etc.</p>


                    <h2>Room Usage</h2>
                    <p>
                        A room should not be used/entered by a student/parent/guardian without the presence or
                        permission of a teacher or staff member.
                    </p>


                    <h2>Food and Drink</h2>
                    <p>
                        Food and drink are permitted in the waiting area. Only water in closed bottles are permitted in
                        the music rooms. Damages terms apply.
                    </p>

                    <h2>Behaviour</h2>
                    <p>
                        Students, teachers, and parents/guardians are expected to behave appropriately and respectfully.
                    </p>

                    <span>Last updated 12/01/2025</span>
                </div>


            </main>
        </div>
    )
}
